import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import('@/pages/Login')
const Orders = () => import('@/pages/Orders')
const OrderDetails = () => import('@/pages/OrderDetails')
const NewOrder = () => import('@/pages/NewOrder')
const Chats = () => import('@/pages/Chats')
const Chat = () => import('@/pages/Chat')
const newChat = () => import('@/pages/NewChat')
const WithdrawalMoney = () => import('@/pages/WithdrawalMoney')
const Teachers = () => import('@/pages/Teachers')
const TeacherDetails = () => import('@/pages/TeacherDetails')
const Parents = () => import('@/pages/Parents')
const ParentDetails = () => import('@/pages/ParentDetails')
const Students = () => import('@/pages/Students')
const Appeals = () => import('@/pages/Appeals')
const Notifications = () => import('@/pages/Notifications')
const TeachersReport = () => import('@/pages/TeachersReport')
const UsersReport = () => import('@/pages/UsersReport')
const OrdersReport = () => import('@/pages/OrdersReport')
const ParentsReport = () => import('@/pages/ParentsReport')
const PaymentsReport = () => import('@/pages/PaymentsReport')
const Arbitrations = () => import('@/pages/Arbitrations.vue')
const Arbitration = () => import('@/pages/Arbitration.vue')
const Newsletters = () => import('@/pages/Newsletters.vue')
const NewslettersNew = () => import('@/pages/NewslettersNew.vue')
const NewParent = () => import('@/pages/NewParent.vue')
const Employees = () => import('@/pages/Employees.vue')
const NewEmployee = () => import('@/pages/NewEmployee.vue')

const Promos = () => import('@/pages/Promos.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/orders/new',
    name: 'NewOrder',
    component: NewOrder
  },
  {
    path: '/orders/:id',
    name: 'Order',
    component: OrderDetails
  },

  {
    path: '/chats',
    name: 'Chats',
    component: Chats
  },
  {
    path: '/chats/new',
    name: 'newChat',
    component: newChat
  },
  {
    path: '/chats/:id',
    name: 'Chat',
    component: Chat
  },
  {
    path: '/withdrawalMoney',
    name: 'WithdrawalMoney',
    component: WithdrawalMoney
  },
  {
    path: '/employees',
    name: 'Employees',
    component: Employees
  },
  {
    path: '/employees/new',
    name: 'NewEmployee',
    component: NewEmployee
  },
  // {
  //   path: '/employees/:id/',
  //   name: 'EmployeeDetails',
  //   component: EmployeeDetails
  // },
  {
    path: '/teachers',
    name: 'Teachers',
    component: Teachers
  },
  {
    path: '/teachers/:id',
    name: 'TeacherDetails',
    component: TeacherDetails
  },
  {
    path: '/parents',
    name: 'Parents',
    component: Parents
  },
  {
    path: '/parents/new',
    name: 'NewParent',
    component: NewParent
  },
  {
    path: '/parents/:id/',
    name: 'ParentDetails',
    component: ParentDetails
  },
  {
    path: '/students',
    name: 'Students',
    component: Students
  },
  {
    path: '/appeals',
    name: 'Appeals',
    component: Appeals
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  {
    path: '/teachersReport',
    name: 'TeachersReport',
    component: TeachersReport
  },
  {
    path: '/usersReport',
    name: 'UsersReport',
    component: UsersReport
  },
  {
    path: '/arbitrations',
    name: 'Arbitrations',
    component: Arbitrations
  },
  {
    path: '/arbitrations/:id',
    name: 'Arbitration',
    component: Arbitration
  },
  {
    path: '/ordersReport',
    name: 'OrdersReport',
    component: OrdersReport
  },
  {
    path: '/parentsReport',
    name: 'ParentsReport',
    component: ParentsReport
  },
  {
    path: '/paymentsReport',
    name: 'PaymentsReport',
    component: PaymentsReport
  },
  {
    path: '/newsletters',
    name: 'Newsletters',
    component: Newsletters
  },
  {
    path: '/newsletters/new',
    name: 'NewslettersNew',
    component: NewslettersNew
  },
  {
    path: '/promos',
    name: 'Promos',
    component: Promos
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
